import React from 'react';

const Hero = ({
    roofline,
    headline,
    subline,
}) => {
    return (
        <div className="max-w-screen-xl px-4 mx-auto grid gap-12 lg:gap-20 md:grid-cols-2 relative">
            <span className="absolute top-0 right-0 z-0 block w-full h-full md:bg-right-top bg-no-repeat bg-top lg:bg-cover opacity-50 lg:opacity-100 bg-hero" style={{ 'background-size': '100%' }} ></span>
            <div className="lg:pr-10 z-10 md:order-first">
                <span className="block w-32 h-1 bg-teal-500 md:mt-16 mb-8 lg:mb-12"></span>
                <p className="text-xl lg:text-2xl tracking-wider uppercase text-secondary-base text-gray-900">{roofline}</p>
                <h1 className="text-3xl lg:text-5xl font-bold leading-snug text-gray-900">{headline}</h1>
                <p className="text-lg lg:text-xl mt-8 text-gray-500">{subline}</p>
                <a href="mailto:kontakt@devinmunsch.de" className="inline-block bg-teal-500 hover:bg-teal-700 text-white font-bold py-3 px-20 rounded-full mt-12 w-full md:w-auto text-center">Let´s talk!</a>
            </div>
            <div className="flex justify-self-center justify-center items-center z-10 order-first">
                <picture alt="Devin Munsch" className="md:mt-4 w-3/5 md:w-3/4">
                    <source srcSet="/img/devinmunsch.webp" type="image/webp" />
                    <img src="/img/devinmunsch.jpg" className="border-teal-500 border-4 lg:border-8 rounded-full inline" alt="Devin Munsch" />
                </picture>
            </div>
        </div>
    )
}

export default Hero