import React from 'react'

const Wrapper = (
    props
) => {
    return (
        <div className={`max-w-screen-xl mt-24 lg:mt-32 px-4 mx-auto z-10 ${ props.className }`}>
            {props.children}
        </div>
    )
}

export default Wrapper