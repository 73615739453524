import React from 'react'

const Box = (
    props
) => {
    return (
        <div className={`border-4 rounded grey-500 border-opacity-100 px-8 py-4 ${ props.className }`}>
            {props.children}
        </div>
    )
}

export default Box