import React from 'react';
import Wrapper from '../structure/wrapper'
import Box from '../atoms/box'

const Projects = () => {
    return (
        <Wrapper>
            <h2 className="text-2xl lg:text-3xl tracking-wider uppercase text-secondary-base font-bold">A project of mine:</h2>
            <div class="grid gap-10 lg:gap-20 md:grid-cols-4 mt-8">
                <Box className="text-center">
                    <a href="https://casalist.de" rel="noreferrer" title="Visit casalist.de" target="_blank">
                        <img src="/img/casalist.svg" className="" alt="casalist" />
                    </a>
                </Box>
            </div>
        </Wrapper>
    )
}

export default Projects