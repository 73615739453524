import React from "react"

import Layout from "../components/layout"
import Hero from "../components/content/hero"
import Projects from "../components/content/projects"

const IndexPage = () => {
  return (
    <Layout>
      <Hero
        roofline="Experienced Software Engineer"
        headline="Crafting Tech Solutions with Expertise and Insight"
        subline="Specializing in PHP, Laravel, automated testing, Google Cloud Platform and BigQuery. Proficient in Agile Project Management and leveraging data for strategic insights, driving business growth."
      />
      <Projects />
    </Layout>
  )  
}

export default IndexPage